import React , {useEffect,useState,useRef} from 'react'
import {  NavLink, Link, useHistory, useLocation} from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import {RichText} from 'prismic-reactjs';
import { Media } from 'react-breakpoints';
import {Helmet} from "react-helmet";

import SwiperCore, { Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";


/*

Local

*/

import {DragWall} from './components/DragWall.js';
import {PrismicAPI} from './components/Prismic.js';
import {Tooltip} from './components/Tooltip.js';
import {isTouch,IsTouch,NotTouch,TouchClass} from './components/IsTouch.js';
import {Sort} from './components/Sort.js';

import {useBodyClass} from './hooks/useBodyClass.js';

/*

Styles

*/

import 'swiper/swiper.scss';
import './App.scss';

/*

Assets

*/

import cover from'./img/BookCover.jpg';

/*

https://www.react-spring.io/docs/hooks/examples
https://use-gesture.netlify.app/


*/

// install Swiper modules
SwiperCore.use([Virtual]);

const INTRO_TIMER = 0;

const EasingFunctions = {
  // no easing, no acceleration
  linear: t => t,
  // accelerating from zero velocity
  easeInQuad: t => t*t,
  // decelerating to zero velocity
  easeOutQuad: t => t*(2-t),
  // acceleration until halfway, then deceleration
  easeInOutQuad: t => t<.5 ? 2*t*t : -1+(4-2*t)*t,
  // accelerating from zero velocity 
  easeInCubic: t => t*t*t,
  // decelerating to zero velocity 
  easeOutCubic: t => (--t)*t*t+1,
  // acceleration until halfway, then deceleration 
  easeInOutCubic: t => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
  // accelerating from zero velocity 
  easeInQuart: t => t*t*t*t,
  // decelerating to zero velocity 
  easeOutQuart: t => 1-(--t)*t*t*t,
  // acceleration until halfway, then deceleration
  easeInOutQuart: t => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
  // accelerating from zero velocity
  easeInQuint: t => t*t*t*t*t,
  // decelerating to zero velocity
  easeOutQuint: t => 1+(--t)*t*t*t*t,
  // acceleration until halfway, then deceleration 
  easeInOutQuint: t => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
}

/* Polyfill array.find */

/*
if (!Array.prototype.find) {
        Array.prototype.find = function(predicate) {
            if (this == null) {
                throw new TypeError('Array.prototype.find called on null or undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var thisArg = arguments[1];
            var value;
            
            for (var i = 0; i < length; i++) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
            return undefined;
        };
    }
*/

function Content({content}){

  return <RichText render={content} linkResolver={linkResolver} />

}

function Paper(props){

      let reading = false;
      let read = props.location.hash.split('#/read/');
      let id;
      if(read[1]){
        reading = true;
        id = read[1].split('/')[0];
      }

    let quote = props.quotes.find((q) => q.uid === id);

    let backTo = props.location.pathname;

    
      let shareUrl = window.location.href;
      let shareSubject = quote ? `${quote.first_name}'s frontline story...` : '';
      let shareBody = quote ? `“${quote.quote[0].text}” — ${quote.title}` : '';
    

    return <AnimatePresence>
      {reading && <motion.div initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="Paper">

        <Helmet>
           
            <title>{shareSubject}</title>
            <meta
            name="description"
            content={shareBody}  />

        </Helmet>

        <div className="Paper-scroll">
          <div className="Paper-content">
         
            <div className="Paper-body">
            
              <Content content={quote.quote} />
              <div className="Paper-head">{quote.title}<br /><span>{quote.role}</span><br /><span>{quote.date}</span></div>
              <Content content={quote.body} />
            </div>

              <div className="Paper-share">
              
                  <p>Share {quote.first_name}'s story</p>
               
                <div>
                  <EmailShareButton subject={shareSubject} body={shareBody} url={shareUrl}>
                    <EmailIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'} />
                  </EmailShareButton>
                  <FacebookShareButton quote={shareBody} url={shareUrl}>
                    <FacebookIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'} />
                  </FacebookShareButton>
                  <LinkedinShareButton title={shareSubject} summary={shareBody} source={`https://frontline.site`} url={shareUrl}>
                    <LinkedinIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'}  />
                  </LinkedinShareButton>
                  <RedditShareButton title={shareBody} url={shareUrl}>
                    <RedditIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'} />
                  </RedditShareButton>
                  <TwitterShareButton title={shareBody} url={shareUrl}>
                    <TwitterIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'} />
                  </TwitterShareButton>
                  <WhatsappShareButton title={shareBody} url={shareUrl}>
                    <WhatsappIcon size={32} round={true} bgStyle={{fill:'transparent'}} iconFillColor={'black'} />
                  </WhatsappShareButton>

                 
                </div>
              </div>
              <Link className="Paper-back" to={backTo}><span>Back</span></Link>

          </div>

          <Link className="Paper-close" to={backTo}><span>Back</span></Link>
        </div>
        
      </motion.div>}
      </AnimatePresence>
}

const Index = React.memo(({quotes}) => {

    function ease(progress,amt){
      let prog = progress;
      if(prog > 1) prog = 1;

      let delay = EasingFunctions['easeOutQuad'](prog) * amt;
      return delay;
    }

    return <motion.div initial="initialFade"
      animate="in"
      exit="out"
      variants={pageVariants}
      className="Index"> 
      
          <div className="Index-content">
            <Sort dir="asc" prop="surname" items={quotes}>
            {
              ({items}) => items.map(
                (quote,ix) => <motion.div
                          key={ix}
                          initial={{
                            opacity: 0,
                            y: ease(ix/50,30),
                          }}
                          animate={{
                            opacity: 1,
                            y: 0,
                            transition: {
                              ease: "easeOut", 
                              duration: 0.5 ,
                              delay: ease(ix/50,0.7),
                            },
                          }}>
                          <Link to={`#/read/${quote.uid}/`} className="Index-item">
                            <div>{quote.title}</div>
                            <div>{quote.role}</div>
                            
                          </Link>
                        </motion.div>
              )
            }
            </Sort>
            </div> 
      </motion.div>
})

function Basic({doc,content,description=null,title=null,modifier='default'}){

    useBodyClass(`page--light`);

    return <motion.div initial="initialFade"
      animate="in"
      exit="out"
      variants={pageVariants}
      className={`Basic Basic--${modifier}`}> 
          

          <div className="Basic-content">
            
              <Content content={content} />
           
          </div> 
      </motion.div>
}

function Act({doc,content,buy,buy_cta}){

    useBodyClass(`page--act`);

    return <motion.div initial="initialFade"
      animate="in"
      exit="out"
      variants={pageVariants}
      className={`Basic Basic--act`}> 
      
          <div className="Basic-content">
            
              <div className="Basic-cols">
                <div><Content content={content} /></div>
                <div>
                  
                  <Content content={buy} />

                  <div className="Product">
                    <div className="Product-media"><img src={cover} alt={'Buy the Frontline book to support this project'} /></div>
                    <div className="Product-button"><span>{buy_cta}</span></div>
                    <div className="Product-form">

                      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">

                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input type="hidden" name="hosted_button_id" value="3HEELMUS4WDX8" />
                        {/*<input type="image" src="https://www.paypalobjects.com/en_US/GB/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal – The safer, easier way to pay online!" /> */}
                        
                        <button className="Product-submit" type="submit" name="submit"></button>

                      </form>
                    </div>
                  </div>

                  

                </div>
              </div>

          </div> 
      </motion.div>
}

const pageVariants = {
  initial: {
    opacity: 0,
    scale: 1,
    transformOrigin:`30% 50%`
  },
  initialFade: {
    opacity: 0,
    scale: 1,
  },
  in: {
    opacity: 1,
    scale: 1
  },
  out: {
    opacity: 0,
    scale: 1.02
  }
};


/*const HashRoute = ({ hash, ...routeProps }) => (
  <Route
    render={({ location }) => (
      (location.hash.match(hash)) && <Route {...routeProps} />
    )}
  />
);*/

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'category') {
    return `/category/${doc.uid}`
  }

  // URL for a product type
  if (doc.type === 'product') {
    return `/product/${doc.uid}`
  }

  // URL for a page type
  if (doc.type === 'page') {
    return `/${doc.uid}`
  }

  // Backup for all other types
  return '/'
}

function Intro({content,cta,onClick}){
  return (
    <div className="Intro" onClick={onClick}>
      <div className="Intro-content">
        <Content content={content} />
      </div>
      <div className="Intro-cta">
        <span>{cta}</span>
      </div>
    </div>
  )
}

function ResponsiveWall({items,children}){

  return (
  <Media>
      {({ breakpoints, currentBreakpoint }) => {

        switch (currentBreakpoint) {
          case 'mobile':
            return <SwipeWall items={items} children={children} />
          default:
            return <DragWall items={items} children={children} sensitivity={isTouch ? 1.5 : 2} />
        }
      }}
    </Media>
  )

}

function TouchInstruction(){

  return (
  <Media>
      {({ breakpoints, currentBreakpoint }) => {

        switch (currentBreakpoint) {
          case 'mobile':
            return 'Swipe left or right'
          default:
            return 'Drag to explore'
        }
      }}
    </Media>
  )

}

function SwipeWall({items,children}){

  return (
  <div className="Quotes--swiper">
  <Swiper
      loop={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >

      {
        items.map(
          (item,ix) => {

            return  <SwiperSlide key={'slide_'+item.id}>{children({item})}</SwiperSlide>

          }
        )
      }
    </Swiper>
    </div>
    )

}

function Meta({data}){

  let matchKey = Object.keys(data).filter((k)=>(window.location.href.match(k))).pop();
  if(!matchKey) return null;

  let metaData = data[matchKey];

  return <Helmet>
           
            <title>{metaData.title}</title>
            <meta
            name="description"
            content={metaData.description}  />

        </Helmet>


}


function App() {

  const history = useHistory();
  const location = useLocation();

  const [state,setState] = useState(
    {
      quotes : [],
      shuffled : []
    }
  );

  const [menuOpen,setMenuOpen] = useState(false);
  
  const [showContent,setShowContent] = useState(false);
  const [seenIntro,setSeenIntro] = useState(location.hash !== '');

  const [seenTooltip,setSeenTooltip] = useState(false);

  const pageviews = useRef(0);

  useEffect(() => {
    history.listen((args)=>{
      pageviews.current++;
    })
  }, [history]);

  let isHome = location.pathname === '/';
  let reading = window.location.hash.match('read');
  
  let isFirstPage = pageviews.current === 0;

  let hideNav = isHome && isFirstPage && !seenIntro;

  return (
    <TouchClass>

     <PrismicAPI
     endpoint={`https://frontline.cdn.prismic.io/api/v2`}
     loader={()=><div>Loading...</div>}
     onLoad={({docs})=>{
      
      
      let quotes = docs.filter((doc)=>doc.type === 'story').map((doc)=>{
       
        return {
          id: doc.id,
          uid : doc.uid,
          surname : doc.data.title.toLowerCase() === 'anonymous' ? 'zzzzz' : doc.data.title.split(' ').pop(),
          ...doc.data
        }
      });
      

      let toShuffle = quotes.length < 100 ? quotes.concat(quotes).concat(quotes) : quotes;
      let shuffled = shuffle(toShuffle);

      setTimeout(()=>{
        setShowContent(true);
      },INTRO_TIMER);

      setState({
        quotes : quotes,
        shuffled : shuffled,
        homepage : docs.filter((doc)=>doc.type === 'home')[0]
      });

    }}>

      {({isLoaded,docs}) => (

        <React.Fragment>

        <Meta data={{
          '/' : {
            title : 'Frontline — Voices from the NHS in a time of crisis',
            description : 'Read the stories of the UK health workers who bore the physical and emotional brunt of the first wave of the Covid-19 crisis'
          },
          '/about/' : {
            title : 'Frontline — About the project',
            description : 'A living archive to record the stories of the men and women who found themselves in a fight for our lives'
          
          },
          '/index/' : {
            title : 'Frontline — Index of contributors',
            description : 'The voices of the health workers who bore the physical and emotional brunt of the first wave'
          
          },
          '/foreword/' : {
            title : 'Frontline — Foreword by Robert Crampton',
            description : 'When did you first realise this was going to be serious? Or actually, not just serious, serious does not nearly do the last 12 months justice...'
          
          },
          '/act/' : {
            title : 'Frontline — Act Now',
            description : 'Share your story, resources and ways to support the cause'
          
          },
          '/credits/' : {
            title : 'Frontline — Credits',
            description : 'The creatives who donated their skills to give NHS staff a platform for their voices to be heard'
          
          }
        }} />

        {showContent && <div className={`App-content App-content--${hideNav ? 'hideNav' : 'showNav'} App-content--${menuOpen ? 'showMenu' : 'hideMenu'}`}>

          <a href="#nav" className="App-burger App-burger--open" onClick={(ev)=>{
            ev.preventDefault();
            setMenuOpen(true);
          }}>Menu</a>

           <a href="#main" className="App-burger App-burger--close" onClick={(ev)=>{
            ev.preventDefault();
            setMenuOpen(false);
          }}>Close</a>
         

          <ul className="App-nav" id="nav" onClick={()=>setMenuOpen(false)}>
            

            <li><NavLink to="/" exact={true}>Home</NavLink></li>
            <li><NavLink to="/about/">About</NavLink></li>
            <li><NavLink to={"/index/"}>Index</NavLink></li>

            <li><NavLink to="/foreword/">Foreword</NavLink></li>

          </ul>



          <main id="main">
              {
                (()=>{

                  if(isLoaded){

                    if(location.pathname.match('/index/')) return <Index key={'index'} quotes={state.quotes} />
                    if(location.pathname.match('/about/')) return <Basic key={'about'} modifier={'about'} content={state.homepage.data.about} />
                    if(location.pathname.match('/foreword/')) return <Basic key={'foreword'} modifier={'foreword'} content={state.homepage.data.foreward} />
                    if(location.pathname.match('/credits/')) return <Basic key={'credits'} modifier={'credits'} content={state.homepage.data.credits} />
                    if(location.pathname.match('/act/')) return <Act key={'act'} content={state.homepage.data.act_now} buy={state.homepage.data.buy} buy_cta={state.homepage.data.buy_cta}  />

                  }

                })()
              }
            
             
          </main>

         { isLoaded && <Paper location={location} quotes={state.quotes} /> }


         <NotTouch>
           <Tooltip>
            { /*isLoaded && !seenIntro && isHome && 
              <div className="Tooltip-caption">
                Enter
              </div>
            */}
            {(()=>{

              if(isLoaded && seenIntro && isHome && !reading && !seenTooltip){
                return (
                <div className="Tooltip-caption" ref={() => {
                  setTimeout(()=> setSeenTooltip(true),5000);
                }}>
                  Drag or Scroll
                </div>
                )
              }

            })()}
            
           </Tooltip>
         </NotTouch>

         <IsTouch>
            { isLoaded && seenIntro && isHome && !reading &&

              <div className="Tooltip-touch-caption">
                <TouchInstruction />
              </div>
              
            }
         </IsTouch>


         <AnimatePresence>
         { isLoaded && !seenIntro && isHome && <motion.div initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants} className="Intro-wrap" onClick={()=>setSeenIntro(true)}><Intro content={state.homepage.data.intro} cta={state.homepage.data.call_to_action}  /></motion.div> }
         </AnimatePresence>


         { isLoaded && seenIntro && isHome && <ResponsiveWall items={state.shuffled}>
          {
            ({item}) => (
              <div className="Quote">
                <div>

                  <Content content={item.quote} />
                  
                
                  <div className="Quote-foot">
                  {/*{item.title}, {item.role}<br />*/}
                  <Link to={`#/read/${item.uid}/`}>Read {item.title.toLowerCase() === 'anonymous' ? 'this' : `${item.first_name}’s`} story</Link>
                  </div>
                  
                </div>
              </div>
            )
          }
         </ResponsiveWall>}
      


         <div className="App-cta" onClick={()=>setMenuOpen(false)}>
          <Link className="Link Link--cta" to="/act/"><span>Act Now</span></Link>
         </div>

          <ul className="App-foot" onClick={()=>setMenuOpen(false)}>
            <li><NavLink to="/credits/">Credits</NavLink></li>
          </ul>

         </div>}
         </React.Fragment>

      )}

    </PrismicAPI>
    </TouchClass>
  );
}



export default App;
