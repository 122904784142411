import React, { useRef,useState } from 'react'
import { useGesture } from 'react-use-gesture'
import { useSpring, a , config} from 'react-spring'

const styles = {
  container: { position: 'relative', height: '100%', width: '100%' }
}

export default function Slider({ items, width = 600, visible = 4, style, children, onMove = null , onRest= null, sensitivity = {drag : 2, wheel : 1}}) {

  const [{ x, y ,scale }, set] = useSpring(() => ({ x: 0, y: 0,scale:1,config:config.default})) // was config.default
  const [mouse,setMouse] = useState(()=>[0,0]);




  const pos = useRef({
    x:0,
    y:0,
    scale:1,
    mouse : [0,0],
    mouseScreenPos : [0,0]
  });

  let maxScale = 0.97; // Varaible so we can make dynamic

  const bind = useGesture({
    onMove: ({xy:[x,y]}) => {
      pos.current.mouse = [x - pos.current.x,y - pos.current.y];
      pos.current.mouseScreenPos = [x,y];
    },
    onDrag: ({ first,intentional, active,down, offset:[x,y],movement: [mx, my],delta:[dx,dy],vxvy:[vx,vy] }) => {

      //if(!intentional) return;
      


      pos.current.x += dx * sensitivity.drag * Math.abs(Math.max(1,vx));
      pos.current.y += dy * sensitivity.drag * Math.abs(Math.max(1,vy));

      pos.current.scale = active ? maxScale : 1;

      

      set({
        x: pos.current.x,
        y:pos.current.y,
        scale:pos.current.scale,
        onRest : (args)=> {
            // Complete
            
            pos.current.mouse = [pos.current.mouseScreenPos[0] - pos.current.x,pos.current.mouseScreenPos[1] - pos.current.y];

            setMouse([
              pos.current.mouse[0],
              pos.current.mouse[1], 
            ]);

            if(onMove){
              onMove({x:args.value.x,y:args.value.y});
            }

            if(onRest){
              onRest({x:args.value.x,y:args.value.y});
            }
        },
        onStart:({x,y})=>{
          
        },
        onChange:({x,y})=>{
          
          pos.current.mouse = [pos.current.mouseScreenPos[0] - x,pos.current.mouseScreenPos[1] - y];


          if(onMove){
            onMove({x,y});
          }
        }
      });
      if(first){
        setMouse([
          pos.current.mouse[0],
          pos.current.mouse[1], 
        ]);
      }
    },
    onWheel: ({ first,intentional, active, down, offset:[x,y],movement: [mx, my],delta:[dx,dy],vxvy:[vx,vy] }) => {
      

      if(!intentional) return;
      // Invert delta from mousewheel

      pos.current.x -= dx * sensitivity.wheel;
      pos.current.y -= dy * sensitivity.wheel;

      let mvx = Math.abs(dx);
      let mvy = Math.abs(dy);

      let maxVelocity = 20;

      let scaleVelocity = Math.min(Math.max(mvx,mvy),maxVelocity) / maxVelocity;
      if(scaleVelocity <= 0.1) scaleVelocity = 0;

      let scaleAmt = 1 - ((1 - maxScale) * scaleVelocity);
    

      pos.current.scale = (active) ? scaleAmt : 1;


      set({
        x: pos.current.x,
        y:pos.current.y,
       scale:pos.current.scale,
       onRest : (args)=> {

          pos.current.mouse = [pos.current.mouseScreenPos[0] - pos.current.x,pos.current.mouseScreenPos[1] - pos.current.y];

            setMouse([
              pos.current.mouse[0],
              pos.current.mouse[1], 
            ]);

          // Wheel complete
          if(onMove){
              onMove({x:args.value.x,y:args.value.y});
            }

            if(onRest){
              onRest({x:args.value.x,y:args.value.y});
            }
        },
        onChange:({x,y})=>{

          pos.current.mouse = [pos.current.mouseScreenPos[0] - x,pos.current.mouseScreenPos[1] - y];



          if(onMove){
            onMove({x,y});
          }
        }
      });

     
      if(first){
    
        setMouse([
          pos.current.mouse[0],
          pos.current.mouse[1], 
        ]);
      }

    },

  })



  return (
    <div {...bind()} style={{ ...style, ...styles.container }}>
       <a.div style={{willChange: 'transform',transformOrigin:`${mouse[0]}px ${mouse[1]}px`,userSelect:'none','touchAction':'none',scale,x,y}}>
        {/*<div style={{position:'absolute',width:25,height:25,top:mouse[1],left:mouse[0],background:'red'}} />*/}
        {children}
      </a.div>
    </div>
  )
}
